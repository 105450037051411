<template>
  <b-card :title="$t('graphic')">
    <b-overlay
      variant="transparent"
      :show="show"
      :opacity="1.00"
      :blur="'2px'"
      rounded="lg"
    >
      <div class="d-flex justify-content-between flex-wrap mb-2">
        <div>
          <span class="text-muted">{{ type }}</span>
        </div>
        <b-badge
          v-show="notS"
          variant="light-danger"
        >
          <span> {{ $t('graphicShow') }}</span>
        </b-badge>
      </div>

      <app-echart-stacked-area
        :key="renderiza"
        :option-data="option"
      />
    </b-overlay>
  </b-card>
</template>
<script>

import { BCard, BBadge, BOverlay } from 'bootstrap-vue'
import AppEchartStackedArea from '@core/components/charts/echart/AppEchartStackedArea.vue'
import CloudWatchService from '../services/CloudWatchService'

export default {
  components: {
    BCard,
    AppEchartStackedArea,
    BBadge,
    BOverlay,
  },
  props: {
    data: {
      default: [],
    },
    valores: {
      default: [],
    },
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      service: [],
      task: null,
      renderiza: 0,
      show: false,
      notS: false,
      metrics: [],
      startDate: '',
      endDate: '',
      time: null,
      serverUrl: null,
      period: null,
      apikey: null,
      percentErrorRate: null,
      messagesPerHour: null,
      option: {
        xAxisData: [],
        series: [],
      },
    }
  },
  watch: {
    valores() {
      this.option.xAxisData = []
      this.option.series = []
      this.service = []

      const fecha1 = this.valores[0]
      const fecha2 = this.valores[1]

      function monthDiff(d1, d2) {
        let months
        months = (d2.getFullYear() - d1.getFullYear()) * 12
        months -= d1.getMonth() + 1
        months += d2.getMonth()
        return months <= 0 ? 0 : months
      }
      if (monthDiff(new Date(fecha1), new Date(fecha2)) === 0) {
        this.notS = false

        this.startDate = fecha1
        this.endDate = fecha2
      } else {
        this.renderiza += 1
        this.option = {
          xAxisData: [],
          series: [],
        }
        this.notS = true
      }
    },
    endDate() {
      this.fillData()
    },
    time() {
      try {
        const separacion = this.time.split('to')
        const i = new Date(separacion[0])
        const f = separacion[1] != null ? new Date(separacion[1]) : null

        if (f != null) {
          if (i.getTime() === f.getTime()) {
            const start = i
            start.setHours(0, 0, 0, 0)
            this.startDate = start

            const end = f
            end.setHours(23, 59, 59, 999)
            this.endDate = end
          } else {
            this.startDate = i
            this.endDate = f
          }
        }
      // eslint-disable-next-line no-empty
      } catch (error) {
      }
    },
    service: {
      handler() {
        this.service.forEach(element => {
          try {
            this.messagesPerHour = (element.datasets.tagged('mqtt').sum() * 60)
              / element.backfillMinutes
            this.percentErrorRate = (element.datasets.tagged('errors').sum()
                / element.datasets.tagged('graphics').sum())
              * 100
            this.rellenar(element.datasets.tagged('graphics').asArray())
          } catch (error) {
            console.log(error)
          }
        })
        this.renderiza += 1
        this.show = false
      },
      deep: true,
    },

  },
  mounted() {
    const [start, end] = this.valores
    this.startDate = start
    this.endDate = end
  },

  beforeDestroy() {
    this.data.forEach((element, i) => {
      this.service[i].stop()
    })
  },
  methods: {
    getTimeNow() {
      function getFirstDayOfMonth(year, month) {
        return new Date(year, month, 1)
      }

      const date = new Date()
      const firstDayCurrentMonth = getFirstDayOfMonth(
        date.getFullYear(),
        date.getMonth(),
      )
      this.startDate = firstDayCurrentMonth
      this.endDate = new Date()
    },

    fillData() {
      this.show = true

      let metrics = [{}]
      const cloudWatchServiceOptions = {
        period: 3600,
        backfillMinutes: 2 * 60,
        refreshMinutes: 5,
        startDate: '',
        endDate: '',
        serverUrl: '',
        apikey: '',
      }
      const servicio = []
      this.data.forEach(element => {
        metrics = [{
          id: `graphics${element.id}`,
          tags: ['graphics'],
          label: `${element.name} (${element.chartName})`,
          dimensionName: element.dimensionName,
          dimensionValue: element.dimensionValue,
          dimensionName2: element.dimensionName2,
          dimensionValue2: element.dimensionValue2,
          name: element.chartName,
          namespace: element.namespace,
        }]
        cloudWatchServiceOptions.startDate = this.startDate
        cloudWatchServiceOptions.endDate = this.endDate
        cloudWatchServiceOptions.serverUrl = element.serverUrl
        cloudWatchServiceOptions.apikey = element.apikey

        servicio.push(new CloudWatchService(cloudWatchServiceOptions, metrics))
      })
      this.service = servicio.slice()
    },
    rellenar(data) {
      try {
        if (data.length !== 0) {
          let existe = false
          this.option.series.forEach(element => {
            if (element.id === data[0].id) existe = true
          })
          if (!existe) {
            const dat = []
            this.option.xAxisData = []
            data[0].data.forEach(element => {
              this.option.xAxisData.push(this.fechaFormato(element.t))

              dat.push(element.y)
            })

            this.option.series.push({
              id: data[0].id,
              name: data[0].label,
              type: 'line',
              stack: 'Total',
              showSymbol: false,
              areaStyle: {},
              lineStyle: {
                width: 0,
              },

              data: dat,
            })
          }
        }
      // eslint-disable-next-line no-empty
      } catch (error) {
      }
    },
    fechaFormato(value) {
      const date = new Date(value)
      const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: false,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }
      return value != null
        ? new Intl.DateTimeFormat('default', options).format(date)
        : value
    },
    clickCalendar(id) {
      document.getElementById(id).click()
    },
  },
}
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

#Balance {
  position: relative;
  box-sizing: border-box;
  padding: 1em 1em 1.5em 1em;
  min-height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: minmax(150px, 1fr);
  grid-gap: 1em;
  grid-auto-flow: row dense;
}

.updated {
  font: 1em "Nunito";
  padding-right: 1em;
  line-height: 1.5em;
  position: absolute;
  bottom: 0;
  right: 0;
}
</style>
